(function($) {

// BG IMG preloader
var lateLoadBackgroundImages = function (window, document) {
  "use strict";

  var hideClass = "-invisible";
  var durationClass = "-ON";
  var srcAttr = "data-LLI-src";
  var groupAttr = "data-LLI-group";
  var minVWAttr = "data-LLI-minVW";

  // https://andylangton.co.uk/blog/development/get-viewportwindow-size-width-and-height-javascript
  var _screenWidth = function () {
    var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
    return x;
  }

  // https://john-dugan.com/javascript-debounce/
  // Example: window.addEventListener("resize",debounce(funcToCall, timeToWaitBetweenRefreshes, booleanRunAtStartOrEnd));
  var _debounce=function(e,t,n){var a;return function(){var r=this,i=arguments,o=function(){a=null,n||e.apply(r,i)},s=n&&!a;clearTimeout(a),a=setTimeout(o,t||200),s&&e.apply(r,i)}};
  var _readDataAttrs = function (obj) {
    obj.imgSrc = obj.getAttribute(srcAttr);
    obj.group = obj.getAttribute(groupAttr) || false;
    obj.minVW = obj.getAttribute(minVWAttr) || "0";
    obj.hideClass = obj.classList.item(0) + hideClass;
    obj.durationClass = obj.classList.item(0) + durationClass;
    obj.hasImg = false;
    return obj;
  }


  function getBgImg(obj) {
    var imgNew;
    obj.classList.remove(obj.durationClass);
    obj.classList.add(obj.hideClass);

    imgNew = new Image();
    imgNew.addEventListener("load", function(){

      obj.classList.add(obj.durationClass);

      window.requestAnimationFrame(function(){
        obj.setAttribute("style", "background-image:url(" + obj.imgSrc + ")");
        obj.hasImg = true;
        obj.classList.remove(obj.hideClass);
      });
    });
    imgNew.src = obj.imgSrc;
  }

  function setVisibility(obj, VW) {
    if (VW < obj.minVW) {
      obj.classList.add(obj.hideClass);
    } else {
      obj.classList.remove(obj.hideClass);
    }
  }

  function firstRun(obj, VW, bool) {
    obj = _readDataAttrs(obj);
    if (VW >= obj.minVW) {
      if (bool) {
        getBgImg(obj);
      }
    } else {
      setVisibility(obj, VW);
    }
  }

  function doDemandStuff(obj, VW) {
    if (obj.hasImg) {
      setVisibility(obj, VW);
    } else {
      obj = _readDataAttrs(obj);
      if (VW >= obj.minVW) {
        //if (!obj.group) {
          getBgImg(obj);
        //}
      } else {
        setVisibility(obj, VW);
      }
    }
  }

  var fetchGroup = function (group) {

    var dataAttrs;
    var i;
    var VW = _screenWidth();

    if (!group) {
      return;
    }

    dataAttrs = document.querySelectorAll("[" + groupAttr + "=\"" + group + "\"]");
    i = dataAttrs.length;

    while (i--) {
      doDemandStuff(dataAttrs[i], VW);
    }
  }

  function doStuff(obj, VW) {
    if (obj.hasImg) {
      setVisibility(obj, VW);
    } else {
      obj = _readDataAttrs(obj);
      if (VW >= obj.minVW) {
        if (!obj.group) {
          getBgImg(obj);
        }
      } else {
        setVisibility(obj, VW);
      }
    }
  }

  function init() {

    var dataAttrs = document.querySelectorAll("[" + srcAttr+ "]");
    var i;
    var VW = _screenWidth();

    for (i = 0; i < dataAttrs.length; i++) {
      doStuff(dataAttrs[i], VW);
    }
  }
  init();
  window.addEventListener("resize", _debounce(init, 250, false), false);
  return {
    fetchGroup: fetchGroup
  };
}(window, document);


// Post-load example
window.addEventListener("load", function(){
  // Delay to emulate poor connection, or use a very large image
  // setTimeout(function(){
    lateLoadBackgroundImages.fetchGroup("onload");
  // }, 2000);
}, false);

})(jQuery);